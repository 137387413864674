import React from "react"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import "./styles.scss"
import { DetailOrder } from "../../../customComponents/salesManagementy/DetailOrder"
import queryString from "query-string"

const CustomDetailOrder = ({ location }) => {
  const { orderId, keyTab } = queryString.parse(location.search)
  return (
    <div className="order-detail-wrapper">
      <Topbar />
      <CustomLayout
        selectedPage={
          <div>
            <DetailOrder orderId={orderId} keyTab={keyTab} />
          </div>
        }
      />
    </div>
  )
}

export default CustomDetailOrder
